import { defineStore } from 'pinia';
import axios from 'axios';
import { useCurrentUserStore } from './currentUserStore';

export const useProductStore = defineStore('productStore', {
  state: () => ({
    product: null,
    products: null,
    currentUserStore: useCurrentUserStore(),
  }),
  actions: {
    async fetchProducts() {
      try {
        const response = await axios.get(`/api/v1/accounts/${this.currentUserStore.user.accountId}/products`);
        this.products = response.data;
        this.product = this.products[0];
      } catch (error) {
        console.error('Failed to fetch products:', error);
      }
    },

    async fetchProduct(productId) {
      try {
        const response = await axios.get(`/api/v1/accounts/${this.currentUserStore.user.accountId}/products/${productId}`);
        this.product = response.data;
      } catch (error) {
        console.error('Failed to fetch product:', error);
      }
    },
    clearProduct() {
      this.product = null;
    }
  },
  getters: {
    productName(state) {
      return state.product?.name || 'Unknown Product';
    },
  },
});